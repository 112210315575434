<template>
  <questionnaire-initial-page-template
    :language="locale"
    :language-options="languageOptions"
    vertically-center-content
    without-footer
    @change-language="onChangeLanguage"
  >
    <you-are-connected
      :doctor-info="doctorInfo"
      :alternative-doctor-info="alternativeDoctorInfo"
      @connect-with-other="$emit('connect-with-other')"
      @stay-connected="$emit('stay-connected')"
      @cancel="$emit('cancel')"
    />
  </questionnaire-initial-page-template>
</template>

<script>
import QuestionnaireInitialPageTemplate from '@/modules/questionnaire/new-design-components/templates/QuestionnaireInitialPageTemplate';
import YouAreConnected from '@/modules/questionnaire/new-design-components/YouAreConnected';

export default {
  name: 'ConnectWithOtherDoctorTemplate',
  components: { QuestionnaireInitialPageTemplate, YouAreConnected },
  props: {
    doctorInfo: {
      type: Object,
      required: true
    },
    alternativeDoctorInfo: {
      type: Object,
      default: null
    },
    locale: {
      type: String,
      default: ''
    },
    languageOptions: {
      type: Array,
      default: () => []
    }
  },
  methods: {
    onChangeLanguage(code) {
      this.$emit('change-language', code);
    }
  }
};
</script>

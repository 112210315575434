<template>
  <connect-with-other-doctor-template
    :doctor-info="doctor"
    :alternative-doctor-info="requestedDoctor"
    :locale="locale"
    :language-options="languageOptions"
    @change-language="onLanguageSelect"
    @connect-with-other="onConnectWithOther"
    @stay-connected="onStayConnected"
    @cancel="onCancel"
  />
</template>

<script>
import { get } from 'lodash';
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';

import ConnectWithOtherDoctorTemplate from '@/modules/questionnaire/pages/connect-with-doctor/ConnectWithOtherDoctorTemplate';

import { getHashedId } from '@/utils';
import { transferPatient } from '@/modules/questionnaire/api';

import { changeLanguageMixin } from '@/modules/questionnaire/mixins/changeLanguageMixin';

import rootTypes from '@/store/types';
import { types as questTypes } from '@/modules/questionnaire/store/types';
import { MEDICAL_DATA_TRANSFER_SOURCE } from '@/modules/dashboard/api/constants';

export default {
  name: 'PageConnectWithOtherDoctor',
  components: { ConnectWithOtherDoctorTemplate },
  mixins: [changeLanguageMixin],
  computed: {
    ...mapState({
      influencerSlug: state => state.questionnaire.influencer
    }),
    ...mapGetters({
      locale: rootTypes.getters.GET_LOCALE,
      doctor: questTypes.getters.GET_DOCTOR,
      patient: questTypes.getters.GET_PATIENT,
      requestedDoctor: questTypes.getters.GET_REQUESTED_DOCTOR
    })
  },
  methods: {
    ...mapMutations({
      resetQuestionnaireModule: questTypes.mutations.RESET_MODULE
    }),
    ...mapActions({
      setDoctor: questTypes.actions.SET_DOCTOR,
      updatePatient: questTypes.actions.UPDATE_PATIENT
    }),
    async onLanguageSelect(locale) {
      await this.updatePatient({ locale });

      this.onChangeLanguage(locale);
    },
    async redirectToQuestionnaire() {
      await this.$router.replace({ name: 'DataIsSafeWithUs' });
    },
    async onConnectWithOther() {
      const patientId = get(this.patient, 'id');
      const doctorId = get(this.requestedDoctor, 'id');

      const { status } = await transferPatient({
        patientId,
        doctorId,
        influencerSlug: this.influencerSlug,
        medicalDataTransferSource: MEDICAL_DATA_TRANSFER_SOURCE.QUESTIONNAIRE
      });

      if (status >= 400) {
        return;
      }

      await this.setDoctor(this.requestedDoctor);
      await this.redirectToQuestionnaire();
    },
    onStayConnected() {
      this.redirectToQuestionnaire();
    },
    onCancel() {
      this.$router.replace({
        name: 'QuestionnaireForDoctor',
        params: {
          lang: this.locale,
          hash: getHashedId(this.requestedDoctor.id)
        }
      });

      this.resetQuestionnaireModule();
    }
  }
};
</script>

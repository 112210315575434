<template>
  <div class="you-are-connected u-typography-helvetica u-text">
    <div class="you-are-connected-content flex flex-column flex--align-center">
      <div
        class="you-are-connected-content__title u-typography-helvetica u-text u-text--m u-text--link"
        :class="titleClasses"
      >
        <span v-if="alternativeDoctorInfo">{{
          $t('youAreConnectedPage.title', [doctorInfo.name])
        }}</span>
      </div>
      <span
        v-if="hasCityAndCountry"
        class="you-are-connected-content__sub-title u-typography-helvetica u-text u-text--xs"
        >{{ doctorInfo.city }}, {{ doctorInfo.country }}</span
      >
      <div
        v-if="quotation"
        class="you-are-connected-content__quotation u-typography-helvetica u-text u-text--xs q-mt16"
      >
        "{{ quotation }}"
      </div>

      <u-button
        v-if="hasSeeBioButton"
        class="q-mt16"
        kind="secondary"
        size="small"
        @click="$emit('see-bio')"
        >See bio</u-button
      >

      <template v-if="alternativeDoctorInfo">
        <i18n
          path="youAreConnectedPage.introText"
          tag="div"
          class="you-are-connected-content__choose-alternative-text q-mt16 q-mb24 u-typography-helvetica u-text u-text--xs"
        >
          <span place="currentDoctor">{{ doctorInfo.name }}</span>
          <span place="alternativeDoctor">{{ alternativeDoctorInfo.name }}</span>
        </i18n>

        <u-button
          class="you-are-connected-content__choose-alternative-button q-mb8"
          size="small"
          @click="$emit('connect-with-other')"
        >
          {{ $t('youAreConnectedPage.action.connectWith', [alternativeDoctorInfo.name]) }}</u-button
        >
        <u-button
          class="you-are-connected-content__choose-alternative-button q-mb8"
          kind="secondary"
          size="small"
          @click="$emit('stay-connected')"
          >{{ $t('youAreConnectedPage.action.stayWith', [doctorInfo.name]) }}</u-button
        >
        <u-button
          class="you-are-connected-content__choose-alternative-button"
          kind="text"
          size="small"
          @click="$emit('cancel')"
          >{{ $t('action.cancel') }}</u-button
        >
      </template>
    </div>

    <div
      v-if="hint"
      class="you-are-connected__hint q-mt24 u-typography-helvetica u-text u-text--xs"
    >
      {{ hint }}
    </div>
  </div>
</template>

<script>
import { UButton } from 'universkin-shared';

export default {
  name: 'YouAreConnected',
  components: { UButton },
  props: {
    doctorInfo: {
      type: Object,
      required: true
    },
    alternativeDoctorInfo: {
      type: Object,
      default: null
    },
    hint: {
      type: String,
      default: ''
    },
    quotation: {
      type: String,
      default: ''
    },
    hasSeeBioButton: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    hasCityAndCountry() {
      const { city, country } = this.doctorInfo;

      return city && country;
    },
    titleClasses() {
      return {
        'you-are-connected-content__title--choose-alternative': this.alternativeDoctorInfo
      };
    }
  }
};
</script>

<style lang="scss" scoped>
@import '~universkin-shared/src/assets/styles/scss/colors.scss';

.you-are-connected {
  width: 436px;
  max-width: 100%;

  &__hint {
    color: $color-grey600;
    text-align: center;
  }
}

.you-are-connected-content {
  background: $color-grey50;
  margin-top: 45px;
  position: relative;
  padding: 0 40px 32px 40px;

  &__title {
    margin-top: 55px;
    max-width: 238px;
    text-align: center;

    &--choose-alternative {
      margin-top: 40px;
    }
  }

  &__sub-title {
    color: $color-grey600;
  }

  &__quotation {
    max-width: 260px;
    text-align: center;
    font-style: italic;
    color: $color-grey600;
  }

  &__choose-alternative-text {
    text-align: center;
  }

  &__choose-alternative-button {
    width: 100%;
  }
}
</style>
